import React, { useCallback, useState, useMemo } from "react";
import Input from "../../../components/Input";
import Button from "components/Button";
import { useFormik } from "formik";
import { FILEUPLOADVALUES } from "constants/index";
import { fileUploadSchema } from "utils/validationSchema";
import CheckboxGroup from "../../../components/RadioButton/RadioButton";
import Form from "../../../components/Stripe/Form";
import { CardInfo, ClientInfo, UserActionsData, UserData } from "types";
import { useEffect } from "react";
import { useClientsManager } from "hooks/useClientManager";
import ClientNameAutocomplete from "./ClientNameAutocomplete";
import FileInput from "./FileInput";

type IProps = {
  processPaymentAndUploadData: (arg: UserActionsData) => void;
  isLoading: boolean;
  credits: number;
  getUserData:()=>void;
  processFilesOnly: (arg: UserData) => void;
};
const options = [
  // { label: "Core Report (Nutrition & Longevity)", value: "normal" },
  { label: "Complete Report (All Panels)", value: "special" },
];

const FileUploadInput = ({
  processPaymentAndUploadData,
  isLoading,
  getUserData,
  processFilesOnly,
  credits,
}: IProps) => {
  const { fetchCoachClients, coachClients, setSelectedClient } =
    useClientsManager();

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [open, setOpen] = useState(false);

  const clientsInfo: ClientInfo[] = useMemo(() => {
    return coachClients.map((client) => ({
      name: `${client.client_name}`,
      email: client.client_email,
    }));
  }, [coachClients]);
  useMemo(() => {
    getUserData()
  }, []);

  const isClientNameValid = (clientName: string) => {
    return Boolean(
      clientsInfo.find(
        (client) =>
          client.name.toLowerCase() === clientName.trim().toLowerCase()
      )
    );
  };
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: FILEUPLOADVALUES,
      validationSchema: fileUploadSchema,
      onSubmit: () => {
          const name = searchValue.split(" ");
          processFilesOnly({
            fname: name[0],
            lname: name.slice(1).toString().replaceAll(",", " ") ?? "",
            email: values.clientEmail,
            file: selectedFile!,
            service_type: values.serviceType,
          });
      },
    });

  //* credit card modal
  const handleCloseForm = () => {
    setOpen(false);
  };
  //* file upload handler
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFieldValue("inputFile", file.name);
    }
  };
  //* handle client name
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (!isClientNameValid(value)) setFieldValue("clientEmail", "");
  };
  const completeUserWorkflow = useCallback(
    (cardInfo: CardInfo): void => {
      const name = searchValue.split(" ");
      const data: UserActionsData = {
        cardInfo: {
          cardHolderName: cardInfo.cardHolderName,
          cardNumber: cardInfo.cardNumber,
        },
        paymentInfo: {
          stripe_token: "",
          service_type: values.serviceType,
        },
        userData: {
          fname: name[0],
          lname: name.slice(1).toString().replaceAll(",", " ") ?? "",
          email: values.clientEmail,
          file: selectedFile!,
          service_type: values.serviceType,
        },
      };
      processPaymentAndUploadData(data);
    },
    [processPaymentAndUploadData, selectedFile, values, searchValue]
  );

  useEffect(() => {
    setSelectedClient(undefined);
    fetchCoachClients();
  }, [fetchCoachClients, setSelectedClient]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue) setFieldValue("clientName", searchValue);
    }, 300);
    return () => clearTimeout(timeout);
  }, [searchValue, setFieldValue]);
  return (
    <div>
      <form
        className={`max-w-[800px] font-sans bg-[#FCFCFC] p-10  max-h-[730px] rounded md:shadow-md  justify-center items-center ${
          open && "filter blur"
        }`}
        noValidate
        onSubmit={handleSubmit}
      >
        <h2 className="text-xl mt-5 mb-10 leading-normal">
          Upload your report to get started
        </h2>
        <div>
          <FileInput
            onChange={handleFileChange}
            selectedFile={selectedFile}
            errors={touched.inputFile && errors.inputFile}
          />
          <ClientNameAutocomplete
            searchValue={searchValue}
            setSearchValue={handleSearchChange}
            onSelect={(arg: ClientInfo) => {
              setFieldValue("clientEmail", arg.email);
            }}
            errors={touched.clientName && errors.clientName}
            data={clientsInfo}
          />
          <Input
            type="text"
            name="clientEmail"
            className="mb-4 px-3 font-sans mt-2"
            value={values.clientEmail}
            placeholder="Client Email"
            onChange={handleChange}
            error={touched.clientEmail && errors.clientEmail}
            readOnly={isClientNameValid(searchValue)}
          />
        </div>
        <CheckboxGroup
          options={options}
          onChange={handleChange}
          name="serviceType"
          error={touched.serviceType && errors.serviceType?.toString()}
          value={
            values.serviceType
              ? values.serviceType.toString()
              : values.serviceType
          }
          className="font-sans"
        />
        <Button
          title="Submit"
          className={`text-xl leading-normal`}
          type="submit"
          isLoading={isLoading}
        />
      </form>
      {open && (
        <Form
          onClose={handleCloseForm}
          sendPaymentInfo={completeUserWorkflow}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default FileUploadInput;
