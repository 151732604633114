// @ts-ignore
import { useEffect, useMemo, useState } from "react";
import Table from "components/Table";
import Text from "./components/Textarea/Text";
import { useParams } from "react-router-dom";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { useReportRecords } from "hooks/useReportRecords";
import { RecordsData } from "state/records/types";
import Button from "components/Button";
import { useUser } from "state/user/hooks";
import { useNavigate } from "react-router-dom";
import SubLevelVideo from "./components/Textarea/SubVideo";
import { Link } from "react-router-dom";
import { getNextSubLevel, getPreviousSubLevel } from "constants/categoryOrdering";
import {
  isSinglePageCategory,
  points,
  pointsColor,
  pointsSubLevelColor,
  findVideoLinkByName,
} from "utils/categoriesDetail";
import { sortString } from "utils";
const CategoriesList = () => {
  const { id, category, sub_category } = useParams();
  const [prevLink, setPrevLink] = useState<any>(null)
  const [nextLink, setNextLink] = useState<any>(null)
  const navigate = useNavigate();
  const { reportData, fetchReportRecords, isLoading, updateRecordNote, fetchSublevels } =
    useReportRecords();

  // get the subcategory id by the name
  const subcategoryId = reportData.subcategories.find(
    (subcategory:any) => subcategory.sub_category_name === sub_category!
  );

  const categoryData = reportData.records.filter(
    (record) => record.subcategory === subcategoryId.id!
  )

  const categoryDataTest = useMemo(
    () => reportData[category!],
    [category, reportData]
  );
  useEffect(() => {
    setNextLink(getNextSubLevel(sub_category!, id))
    setPrevLink(getPreviousSubLevel(sub_category!, id))
  }, [sub_category])
  const { user } = useUser();
  const getTableData = useMemo(
    () => (records) => {
      return records.map((record) => ({        
        value: (typeof record.micronutrient.risk_value.find(value => sortString(value.risk) === sortString(record.risk_value)) !== 'undefined' ?
          record.micronutrient.risk_value.find(value => sortString(value.risk) === sortString(record.risk_value)).value : 0) ,
        gene: record.micronutrient.gene,
        rsID: record.micronutrient.rsID,
        genotype: record.risk_value,
        indication: record.micronutrient.expanded_coaching_indication,
        genotype_note: record.micronutrient.genotype_notes.split(/(?=\s[A-Z]{2}\s=)/g).map((el:any, index:any) => (
          <div key={index}>
            {el}
          </div>
        )),
      }));
    },
    []
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!id || !sub_category) return;
    updateRecordNote(id, sub_category, notesValue, category!);
    // update state with updated notes
    updateNotes(prevNotes => {
      //return [...prevNotes, {report:parseInt(id), subcategory: parseInt(subcategoryId.id), note:notesValue}]
      const newNotes = []
      prevNotes.forEach((note) => {
        if (note.report === parseInt(id) && note.subcategory === parseInt(subcategoryId.id)) {
          newNotes.push({...note, note: notesValue });
        } else {
          newNotes.push(note);
        }
      })
      // if note doesn't exist for this report and subcategory, add it
      if (prevNotes.findIndex((note) => note.report === parseInt(id) && note.subcategory === parseInt(subcategoryId.id)) === -1) {
        newNotes.concat(prevNotes);
        newNotes.push({ report: parseInt(id), subcategory: parseInt(subcategoryId.id), note: notesValue });
      }
      return newNotes;
    })
  };

  const [notes, updateNotes] = useState(reportData.notes);
  const handleSubmitSubLevel = (
    e: React.FormEvent<HTMLFormElement>,
    subLevelKey: string,
    categoryName: string

  ) => {
    e.preventDefault();
    if (!id || !sub_category) return;
    updateRecordNote(id, categoryName, subLevelNotes[subLevelKey], category!);
  };
  const updateSubLevelNote = (subLevelKey: string, newValue: string) => {
    setSubLevelNotes((prevNotes) => ({
      ...prevNotes,
      [subLevelKey]: newValue,
    }));
  };
  const CategoryData = isSinglePageCategory(category!)
    ? categoryDataTest
    : categoryData;

  const first_notes = notes.find((note) => note.subcategory === subcategoryId.id)
    ? notes.find((note) => note.subcategory === subcategoryId.id).note
    : "";
  const [notesValue, setNotesValue] = useState("");
  const [subLevelNotes, setSubLevelNotes] = useState<{
    [key: string]: string;
  }>({});

  // in case of directly calling this page
  useEffect(() => {
    if (Object.keys(reportData).length === 0) {
      fetchReportRecords(id!);
    }
  }, [id, fetchReportRecords, reportData]);

  useEffect(() => {
    fetchSublevels(subcategoryId.id!);
  }, [subcategoryId.id, fetchSublevels]);

  useEffect(() => {
    const note = notes.filter((note:any) => note.subcategory === subcategoryId.id).length > 0 
      ? notes.filter((note) => note.subcategory === subcategoryId.id)[0].note 
      : "";
    setNotesValue(note);
  }, [sub_category])

  let filterd_sublevels = []
  if (reportData.sublevels && reportData.sublevels.length > 0) {
    filterd_sublevels = reportData.sublevels.filter((sublevel) => categoryData.some((record) => record.sublevel === sublevel.id));
  }

  const sortCategoryData = (( a:any, b:any ) => {
    if ( a.micronutrient.gene < b.micronutrient.gene ){
      return -1;
    }
    if ( a.micronutrient.gene > b.micronutrient.gene ){
      return 1;
    }
    return 0;
  })

  categoryData.sort(sortCategoryData);

  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="w-full p-6 bg-main sm:px-20">
        <div className="mb-8">
          <p
            className="cursor-pointer font-bold hover:underline"
            onClick={() => navigate(`/categories/${id}`)}
          >
            &#60; Menu
          </p>
        </div>
        <div className={`flex ${!!prevLink ? 'justify-between' : 'justify-end'} my-5`}>
          {!!prevLink &&
            <Button title={"< Back"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(prevLink)} />
          }
          {!!nextLink &&
            <Button title={"Next >"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(nextLink)} />
          }
        </div>
        <div className="flex gap-x-5 items-end mb-[50px]">
          <h2 className="text-xl font-normal">{sub_category}</h2>
          <p className={`${pointsColor(parseFloat(points(categoryData)).toFixed(0), sub_category)} font-semibold font-helvetica text-xl`}>
            {parseFloat(points(categoryData)).toFixed(0)}%
          </p>
        </div>
        {/* {!isSinglePageCategory(category!) &&
          CategoryData &&
          Object.entries(CategoryData).map(
            ([subLevelKey, record]: any) => {
              if (typeof record === "object" && record !== null) {
                const videoLink = findVideoLinkByName(record.subcategory);
                return (
                  <div key={record.subcategory} className="mb-3">
                    <h5 className="font-bold">{record.subcategory} <span className={`${pointsSubLevelColor(parseFloat(((record.total_risk_value / record.total) * 100).toString()).toFixed(0), subLevelKey)} font-semibold ml-3`}>{parseFloat(((record.total_risk_value / record.total) * 100).toString()).toFixed(0)}%</span></h5>
                    {videoLink && <SubLevelVideo src={videoLink} />}
                    <Text text={record.subcategory} />
                    <Table data={getTableData(record.records)} title={record.subcategory} />
                  </div>
                );
              }
              return null;
            }
          )
        } */}
        {filterd_sublevels.map((record) => (
          <div className="mb-3 flex-col-reverse" key={record.id}>
            <div className="mb-3">
              <h5 className="font-bold">{record.sub_level_name} <span className={`${pointsSubLevelColor(parseFloat((points(categoryData.filter((rec)=>rec.sublevel===record.id))).toString()).toFixed(0), record.sub_level_name)} font-semibold ml-3`}>{parseFloat((points(categoryData.filter((rec)=>rec.sublevel===record.id))).toString()).toFixed(0)}%</span></h5>
              <SubLevelVideo src={findVideoLinkByName(record.sub_level_name)}/>
              <Text text={record.description} />
              <Table data={getTableData(categoryData.filter((rec)=>rec.sublevel===record.id))} title={record.sub_level_name} />
            </div>
          </div>
        ))}
        <div>
          <label className="text-xl font-bold font-helvetica mb-3">
            Notes
          </label>
          <form onSubmit={handleSubmit}>
            <textarea
              className="bg-[#FCFCFC] w-full h-[300px] my-5 p-4"
              value={notesValue}
              onChange={(e) => setNotesValue(e.target.value)}
              required
              readOnly={user?.isClient}
            />
            <div className="text-center">
              {!user?.isClient && (
                <Button
                  title="Save"
                  className="text-xl leading-normal max-w-[200px] bg-[#fffbfb] text-black p-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  type="submit"
                />
              )}
            </div>
          </form>
        </div>
        <div className={`flex ${!!prevLink ? 'justify-between' : 'justify-end'} mt-5`}>
          {!!prevLink &&
            <Button title={"< Back"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(prevLink)} />
          }
          {!!nextLink &&
            <Button title={"Next >"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(nextLink)} />
          }
        </div>
      </div>
    </DataLoaderOverlay>
  );
};
export default CategoriesList;
