//@ts-nocheck
import React, {  memo } from "react";
import { useTable } from "react-table";
import { COLUMNS as columns } from "constants/index";
interface IProps {
  data: any;
  title:string
}

const Table: React.FC<IProps> = ({ data, title }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  // console.log(rows);
  return (
    <div className="overflow-x-auto">
      <table
        {...getTableProps()}
        className="font-helvetica mt-4 mb-4  sm:table-fixed w-full"
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, cellIndex:number) => (
                <th
                  {...column.getHeaderProps()}
                  className={`border border-gray-400 px-4 py-2 first:hidden last:hidden text-sm  ${cellIndex === 4 || cellIndex === headerGroup.headers.length - 1 ? 'sm:w-full':'sm:w-[120px]'}`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any, cellIndex: number) => (
                  <td
                    key={cellIndex}
                    {...cell.getCellProps()}
                    className={`border border-black px-4 py-2 text-sm ${
                      cellIndex === row.cells.length - 1 && "hidden"
                    } ${cellIndex === 0 && "hidden"} ${
                      cellIndex === 1 && cell.row.values.value > 0
                        ? 'font-bold italic'
                        : cellIndex === 3 ? 
                        title !== 'POWER/SPRINT' && title !== 'ENDURANCE' && title !== 'STRENGTH TRAINING' && title !== 'MUFAS' ?
                        `font-bold ${cell.row.values.value === 0 ? 'text-[#07bc0c]' : cell.row.values.value === 1 ? 'text-[#f1c40f]' : 'text-[#e74c3c]'}`
                        : `font-bold ${cell.row.values.value <=1  ? 'text-[#000000]' : 'text-[#07bc0c]'}`
                        : ''
                    }`}
                  > 
                    {cellIndex === row.cells.length - 2 ? (
                      <>
                        {cell.value}
                        <br />
                        <br />
                        {cell.row.values.genotype_note && (
                          cell.row.values.genotype_note
                        )}
                      </>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default memo(Table);
