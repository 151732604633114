import ReportTable from "../../components/ReportTable";
import { useEffect, useMemo } from "react";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { useClientsManager } from "hooks/useClientManager";

const Clients = () => {
  const { fetchCoachClients, coachClients, isLoading, setSelectedClient } =
    useClientsManager();
  useEffect(() => {
    setSelectedClient(undefined);
    fetchCoachClients();
  }, [fetchCoachClients, setSelectedClient]);

  const tableData = useMemo(() => {
    return coachClients.map((client) => {
      return {
        col1: client.client_name,
        col2: client.id,
      };
    });
  }, [coachClients]);

  const filteredDataWithSum = tableData.reduce((acc, current:any, index) => {
   const existingEntry = acc.find((item) => item.col1 === current.col1);

  if (existingEntry) {
    acc.push({ col1: `${existingEntry.col1} (${current.col2})`, col2: current.col2 });
  } else {
    acc.push({ col1: `${current.col1}`, col2: current.col2 });
  }

  return acc;
}, [] as { col1: string; col2: number }[]);

  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="h-screen pt-14 bg-main">
        <div className="flex flex-col justify-center">
          <div className="px-[40px] w-full">
            <ReportTable
              data={filteredDataWithSum}
              setSelectedClient={setSelectedClient}
            />
          </div>
        </div>
      </div>
    </DataLoaderOverlay>
  );
};

export default Clients;
