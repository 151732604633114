// @ts-ignore
import { api } from "config";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useLoading } from "state/loading/hooks";
import { useRecords } from "state/records/hooks";

export const useReportRecords = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { reportData, setReportData, selectedCategory,setReportSummary, setSelectedCategory,reportSummary,setReport,report} =
    useRecords();

  const deleteReport = useCallback(
    async (navigate:any,reportID: string) => {
      try {
        setIsLoading(true);
        await api.delete(`reports/${reportID}/delete/`);
        toast.success("Report Deleted Successfully.")
        navigate("/reports")
      } catch (e: any) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const fetchSublevels = useCallback(
    async (recordID: number) => {
      try {
        setIsLoading(true);
        const { data } = await api.get(`sublevel/${recordID}`);
        if (data.success) {
          let newData = { ...reportData };
          newData['sublevels'] = data.data
          setReportData(newData);
        }
      } catch (e: any) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setReportData]
  );
  
  const fetchReportRecords = useCallback(
    async (recordID: string) => {
      try {
        setIsLoading(true);
        const { data } = await api.post("report/records/", {
          entry_no: recordID,
        });
        const categories = await api.get(`categories/`);
        const subcategories = await api.get(`subcategories/`);
        const reportNotes = await api.get(`report/notes/${recordID}`);

        if (data.success){
          setReportData({
            records: data.data, 
            categories: categories.data.data, 
            subcategories: subcategories.data.data,
            notes: reportNotes.data.data,
          });
          setReportSummary(reportNotes.data.data.filter((note:any)=> note.subcategory==null)[0].note);
        }
      } catch (e: any) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setReportData, setReportSummary]
  );
  const fetchClientReport = useCallback(
      async (recordID: string) => {
        try {
          setIsLoading(true);
           const { data } = await api.post(
             "report/generate/",
             {
               entry_no: recordID,
             },    
           );
          if (data.success) 
          return data.data.url
        } catch (e: any) {
          toast.error(e);
        } finally {
          setIsLoading(false);
        }
      },
      [setReport, setIsLoading]
    );
   const fetchSummaryReportRecords = useCallback(
     async (recordID: string,summary:string) => {
       try {
         setIsLoading(true);
         const { data } = await api.patch("/report/update/", {
          
           entry_no: recordID,
           summary:summary
         }); 
        //  if (data.success) 
        //  setReportSummary(data.data.report.summary);
       
       } catch (e: any) {
         toast.error(e);
       } finally {
         setIsLoading(false);
       }
     },
     [setIsLoading, setReportSummary]
   );
const updateRecordNote = useCallback(
  async (recordID:string, subCategory:string, notes:string, category:string) => {
    try {
      setIsLoading(true);
      const { data } = await api.post("/report/category/note/", {
        sub_category_name: subCategory,
        note: notes,
        report_id: recordID,
      });
      if (data.success) {
        toast.success("Note Changed Successfully.");
      } else {
        toast.error("Server responded with an error.");
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  },
  [setIsLoading]
);

  return {
    // fetchClientReports,
    fetchReportRecords,
    isLoading,
    reportData,
    setSelectedCategory,
    selectedCategory,
    deleteReport,
    updateRecordNote,
    reportSummary,
    fetchSummaryReportRecords,
    setReportSummary,
    fetchClientReport,
    report,
    fetchSublevels
  

    // fetchCoachClients,
  };
};
